import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { createCasinoSlice } from './slices/casinoSlice'
import { createCommonSlice } from './slices/commonSlice'
import { createCompetitionsSlice } from './slices/competitionsSlice'
import { createPaymentSlice } from './slices/paymentSlice'
import { createSettingsSlice } from './slices/settingsSlice'
import { createSportsSlice } from './slices/sportsSlice'
import { createUserSlice } from './slices/userSlice'
import type { AllSlices } from './types'

export const useBoundStore = create<AllSlices>()(
  persist(
    (...a) => ({
      casino: createCasinoSlice(...a),
      common: createCommonSlice(...a),
      competitions: createCompetitionsSlice(...a),
      payment: createPaymentSlice(...a),
      sports: createSportsSlice(...a),
      user: createUserSlice(...a),
      settings: createSettingsSlice(...a),
    }),
    {
      name: 'user-store',
      partialize: state => ({ user: state.user }),
      merge: (persistedState, currentState) => {
        return {
          ...currentState,
          user: { ...currentState.user, ...(persistedState as AllSlices).user },
        }
      },
    },
  ),
)
